var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.$t('academic.updateLabel'),"width":600,"centered":"","destroyOnClose":"","confirm-loading":_vm.confirmLoading},on:{"cancel":_vm.closeModal,"ok":_vm.changeLabel}},[_c('a-form-model',{ref:"form",attrs:{"layout":"vertical","model":_vm.form}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('academic.systemLabel')}},[_c('div',{staticClass:"label-container"},[_c('div',{staticClass:"label",style:({
            border: _vm.calcBg ? ("1px solid " + (_vm.calcBg.borderColor)) : false,
            background: _vm.calcBg ? _vm.calcBg.color : false,
          })},[_vm._v(" "+_vm._s(_vm.calcBg ? _vm.$t(("academic." + (_vm.calcBg.key))) : _vm.$t('academic.noLabel'))+" ")])])]),_c('a-form-model-item',{attrs:{"label":_vm.$t('academic.label'),"rules":[
        {
          required: true,
          message: _vm.$t('common.select'),
        } ]}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('common.select')},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}},_vm._l((_vm.options),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(_vm.$t(("allLabel." + (item.key)))))])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }