

























































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import FlexTooltip from '@/components/FlexTooltip.vue'
import debounce from 'lodash/debounce'
import filterGroup from '@/components/filterGroup'
import {
  toPage,
  guid,
  clearEmptyArray,
  judgeVnode,
  handleBatchDownload,
  getToken,
  getSchoolInfo,
} from '@/utils/utils'
import { createPagination } from '@/constant/constant'
import { DropDownController, AcademicLevelController } from '@/services/request.service'
import ChangeLabel from './components/ChangeLabel.vue'
import EditRecord from './components/EditRecord.vue'
import Statement from './components/Statement.vue'
import moment from 'moment'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    FlexTooltip,
    filterGroup,
    ChangeLabel,
    EditRecord,
    Statement,
  },
})
export default class AcademicStanding extends Vue {
  @Prop({ default: true }) private readonly showAll!: boolean

  private pagination: any = createPagination({})
  private dataEmptyFlag: boolean = false

  private loading: boolean = false
  private exportCourseLoading: boolean = false
  private filter: any = {
    // studentName: undefined,
    // studentNum: undefined,
    // houseGroupId: undefined,
    // sectionIds: 0,
    modified: undefined,
    status: undefined,
    addStatus: undefined,
    updatable: undefined,
  }
  private data: Array<any> = []

  private tagList: any = [
    {
      key: 'noLabel',
      value: '无状态',
      enValue: 'No Status',
      borderColor: 'white',
      color: 'white',
    },
    {
      key: 'standing',
      value: '良好',
      enValue: 'Good Standing',
      borderColor: 'rgba(82, 197, 26, 1)',
      color: 'rgba(82, 197, 26, 0.25)',
    },
    {
      key: 'warning',
      value: '学术警告',
      enValue: 'Academic Warning',
      borderColor: 'rgba(249, 219, 19, 1)',
      color: 'rgba(249, 219, 19, 0.25)',
    },
    {
      key: 'probation',
      value: '学术察看',
      enValue: 'Academic Probation',
      borderColor: 'rgba(250, 140, 20, 1)',
      color: 'rgba(250, 140, 20, 0.25)',
    },
  ]

  private extraTag: any = [
    {
      key: 'noLabel',
      value: '无状态',
      enValue: 'No Status',
      borderColor: 'white',
      color: 'white',
    },
    {
      key: 'review',
      value: '学术审查',
      enValue: 'Academic Review',
      borderColor: 'rgba(245, 32, 45, 1)',
      color: 'rgba(245, 32, 45, 0.25)',
    },
  ]

  private get allTags(): any {
    return this.tagList.concat(this.extraTag)
  }

  private options: Array<any> = []
  // private currentTag: string = ''
  private record: any = {}
  private editType: string = ''
  private editVisibility: boolean = false
  private statement: any = {}
  private detailVisiblity: boolean = false
  private recordVisibility: boolean = false
  private statementVisibility: boolean = false

  private moment = moment

  private get studentId(): number {
    return parseInt(this.$route.params.studentId, 10)
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get isZh(): boolean {
    return this.$store.state.locale === 'zh'
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'studentName',
        dataIndex: 'studentName',
        title: this.$t('common.personName'),
        scopedSlots: { customRender: 'name' },
        // width: 270 * this.ratio,
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'studentNum',
        dataIndex: 'studentNum',
        width: 120 * this.ratio,
        title: this.$t('common.studentId'),
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'section',
        dataIndex: 'section',
        width: 70 * this.ratio,
        title: this.$t('common.grade'),
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'advisory',
        dataIndex: 'advisory',
        // width: 200 * this.ratio,
        ellipsis: true,
        title: this.$t('myClass.house'),
        scopedSlots: { customRender: 'advisory' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'schoolYear',
        dataIndex: 'schoolYear',
        width: 110 * this.ratio,
        title: this.$t('common.schoolYear'),
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'semester',
        dataIndex: 'semesterFormat',
        width: 70 * this.ratio,
        title: this.$t('common.term'),
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'status',
        dataIndex: 'status',
        width: 100,
        title: this.$t('common.status'),
        scopedSlots: { customRender: 'status' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
            class: 'status-cell',
          }
        },
      },
      {
        key: 'addStatus',
        dataIndex: 'addStatus',
        width: 100,
        title: this.$t('academic.additionalStatus'),
        scopedSlots: { customRender: 'status' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
            class: 'status-cell',
          }
        },
      },
      this.showAll
        ? [
            {
              key: 'modifiedRecord',
              width: 100 * this.ratio,
              title: this.$t('academic.modifiedRecord'),
              scopedSlots: { customRender: 'modifiedRecord' },
              customCell: (record, index) => {
                return {
                  style: { background: '#fff' },
                  class: 'status-cell',
                }
              },
            },
            {
              key: 'operations',
              width: 200 * this.ratio,
              title: this.$t('common.operations'),
              scopedSlots: { customRender: 'operations' },
              customCell: (record, index) => {
                return {
                  style: { background: '#fff' },
                }
              },
            },
          ]
        : undefined,
    ]
      .flat()
      .filter(item => item)
  }

  private calcBg(text): any {
    return this.allTags.find(item => item.key === text)
  }

  private reset(): void {
    this.filter = {
      // studentName: undefined,
      // studentNum: undefined,
      // houseGroupId: undefined,
      // sectionIds: 0,
      // semesterIds: [],
      modified: undefined,
      status: undefined,
      addStatus: undefined,
      updatable: undefined,
    }
    this.fetchData()
  }

  @Watch('studentId', { immediate: true })
  private onIdChange(val) {
    if (!val) return
    this.getData()
  }

  // private created(): void {
  //   this.getData()
  // }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private getData(): void {
    this.loading = true
    const { modified, status, addStatus, updatable } = this.filter
    const params = {
      // pageCurrent: 1,
      // pageSize: 0,
      // studentName: studentName?.trim() || undefined,
      // studentNum: studentNum?.trim() || undefined,
      // houseGroupId: houseGroupId || undefined,
      // sectionIds: sectionIds || undefined,
      // semesterIds: semesterIds || undefined,
      modified: modified === undefined ? undefined : Boolean(modified),
      // status,
      // addStatus: addStatus ? addStatus : undefined,
      updatable: updatable === undefined ? undefined : Boolean(updatable),
    }
    AcademicLevelController.getAcademicLevel(
      1,
      0,
      this.studentId,
      // params.studentName,
      // params.studentNum,
      // params.houseGroupId,
      // params.sectionIds,
      // params.semesterIds,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      params.modified,
      status,
      addStatus,
      params.updatable
      // params.addStatus
    )
      .then(res => {
        this.data = res.data.items.map(item => {
          return {
            ...item,
            addStatus: item.addStatus ? item.addStatus : 'noLabel',
            addStatusCal: item.addStatusCal ? item.addStatusCal : 'noLabel',
            semesterFormat: `T${item.semester.substr(-1)}`,
          }
        })
        if (!this.data.length) {
          this.dataEmptyFlag = true
        } else {
          this.dataEmptyFlag = false
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private showStatement(record, col): void {
    // if(!record[col.key]) return
    this.editType = col.key
    this.options = col.key === 'status' ? cloneDeep(this.tagList) : cloneDeep(this.extraTag)
    this.record = record
    this.statementVisibility = true
  }

  private showEditModal(record, col): void {
    this.editType = col.key
    this.options = col.key === 'status' ? cloneDeep(this.tagList) : cloneDeep(this.extraTag)
    this.record = record
    this.editVisibility = true
  }

  // private changeTag(label): void {
  //   AcademicLevelController.updateAcademicLevel()
  // }

  private showRecordModal(record): void {
    if (!record.hasEditLog) return
    AcademicLevelController.getStatusDetail(record.semesterId, record.studentId).then(res => {
      this.record = record
      this.statement = res.data
      this.recordVisibility = true
    })
  }

  private changeLabel(res): void {
    this.getData()
  }

  private exportCourse(): void {
    this.exportCourseLoading = true
    const { modified, status, addStatus, updatable } = this.filter
    const params = {
      modified: modified === undefined ? undefined : Boolean(modified),
      updatable: updatable === undefined ? undefined : Boolean(updatable),
    }
    AcademicLevelController.getNotStandingAcademicLevelExport(
      this.studentId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      params.modified,
      status,
      addStatus,
      params.updatable
    )
      .then(res => {
        const students = res.data
        let handleData: any = []
        for (let i = 0; i < students.length; i++) {
          const {
            notStandingCourses,
            editLogs,
            warningCourses,
            probationCourses,
            status,
            addStatus,
            updated,
            ...items
          } = students[i]
          for (let j = 0; j < notStandingCourses.length; j++) {
            handleData.push({
              ...items,
              course: notStandingCourses[j].courseName,
              point: notStandingCourses[j].point,
              formatStatus: this.$t(`academic.${status}`),
              formatAddStatus: addStatus ? this.$t(`academic.${addStatus}`) : '',
              formatUpdated: this.$t(`common.${updated}`),
            })
          }
        }

        const dataIndexList = [
          'lastName',
          'enName',
          'firstName',
          'name',
          'studentNum',
          'sectionName',
          'advisory',
          'advisor',
          'schoolYear',
          'semesterName',

          'formatStatus',
          'formatAddStatus',

          'average',

          'course',
          'point',
          'formatUpdated',
        ]

        const title = [
          this.$t('common.surname'),
          this.$t('common.enName'),
          this.$t('common.givenName'),
          this.$t('common.cnName'),
          this.$t('common.studentId'),
          this.$t('common.grade'),
          this.$t('common.advisory'),
          this.$t('common.tutor'),
          this.$t('common.schoolYear'),
          this.$t('common.term'),
          this.$t('common.status'),
          this.$t('academic.additionalStatus'),
          this.$t('academic.average'),
          this.$t('academic.belowStandard'),
          this.$t('academic.point'),
          this.$t('academic.isUnpdated'),
        ]

        let mainStr: any = []
        mainStr.push(title.join(',') + '\n')
        for (let i = 0; i < handleData.length; i++) {
          const temp: any = []
          for (let j = 0; j < dataIndexList.length; j++) {
            let strItem = handleData[i][dataIndexList[j]]
            strItem = `"${strItem}"`
            temp.push(strItem + '')
          }
          mainStr.push(temp.join(',') + '\n')
        }

        const result = mainStr.join('')
        const url = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(result)
        const link = document.createElement('a') //通过创建a标签实现
        link.href = url
        link.download = `Below_Standard_Courses.csv`
        document.body.appendChild(link)
        link.click()

        this.exportCourseLoading = false
        document.body.removeChild(link)
      })
      .catch(err => {
        console.log(err)
        this.exportCourseLoading = false
      })
  }

  private updateReportStatus(record): void {
    AcademicLevelController.updateReportStatus(record.semesterId, record.studentId)
      .then(res => {
        this.$message.success(this.$tc('common.saveSuccess'))
        record.upDatable = false
      })
      .catch(err => console.error(err))
  }

  private viewGradeBook(id): void {
    this.$router.push({ name: 'gradeBook', params: { studentId: id } }).catch(err => {})
  }
}
