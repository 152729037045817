


















































































import { Component, Vue, Model, Prop, Emit, Watch } from 'vue-property-decorator'
import { AcademicLevelController } from '@/services/request.service'
import moment from 'moment'
import { MenuItem } from 'ant-design-vue/types/menu/menu-item'

const operations = {
  status: function(semesterId, studentId) {
    return AcademicLevelController.getStatusDetail(semesterId, studentId)
  },
  addStatus: function(semesterId, studentId) {
    return AcademicLevelController.getReviewDetail(semesterId, studentId)
  },
}

@Component
export default class Statement extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly record!: any
  @Prop() private readonly editType!: string
  @Prop() private readonly options!: Array<any>

  private data: Array<any> = []
  private calStatus: string = ''
  private status: string = ''
  private moment = moment
  private loading: boolean = false
  private allTagList: any = [
    {
      key: 'standing',
      value: '良好',
      enValue: 'Good Standing',
      borderColor: 'rgba(82, 197, 26, 1)',
      color: 'rgba(82, 197, 26, 0.25)',
    },
    {
      key: 'warning',
      value: '学术警告',
      enValue: 'Academic Warning',
      borderColor: 'rgba(249, 219, 19, 1)',
      color: 'rgba(249, 219, 19, 0.25)',
    },
    {
      key: 'probation',
      value: '学术察看',
      enValue: 'Academic Probation',
      borderColor: 'rgba(250, 140, 20, 1)',
      color: 'rgba(250, 140, 20, 0.25)',
    },
    {
      key: 'noLabel',
      value: '无状态',
      enValue: 'No Status',
      borderColor: 'white',
      color: 'white',
    },
    {
      key: 'review',
      value: '学术审查',
      enValue: 'Academic Review',
      borderColor: 'rgba(245, 32, 45, 1)',
      color: 'rgba(245, 32, 45, 0.25)',
    },
  ]
  private get isStatus(): boolean {
    return this.editType === 'status'
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get calcBg(): any {
    // return this.findLabel(this.calStatus)
    return this.editType === 'status'
      ? this.findLabel(this.record.statusCal)
      : this.findLabel(this.record.addStatusCal)
  }

  private get currentBg(): any {
    // return this.findLabel(this.status)
    return this.findLabel(this.record.status)
  }

  private get average(): any {
    const pointList: Array<number> = this.data.map(item => item.point)
    let average: number = 0
    let sum: number = 0

    if (pointList.length) {
      pointList.forEach(item => {
        sum += item
      })
      average = Math.round(sum / pointList.length)
    }

    return average
  }

  private findLabel(key): any {
    return this.options.find(item => item.key === key)
  }

  private get columns(): any {
    return this.isStatus
      ? [
          {
            key: 'courseName',
            title: this.$t('common.subjectClass'),
            dataIndex: 'courseName',
          },
          {
            key: 'point',
            title: this.$t('academic.point'),
            dataIndex: 'point',
          },
          {
            key: 'standing',
            title: this.$t('academic.statementDetail'),
            dataIndex: 'standing',
            scopedSlots: { customRender: 'standing' },
          },
        ]
      : [
          {
            key: 'semesterName',
            title: this.$t('common.term'),
            dataIndex: 'semesterName',
          },
          {
            key: 'status',
            title: this.$t('common.status'),
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
          },
          {
            key: 'review',
            title: this.$t('academic.additionalStatus'),
            dataIndex: 'review',
            scopedSlots: { customRender: 'review' },
          },
        ]
  }

  @Watch('visible')
  private onVisibleChange(newVal): void {
    this.data = []
    // this.calStatus = ''
    this.status = ''
    if (newVal) {
      this.loading = true
      operations[this.editType](this.record.semesterId, this.record.studentId)
        .then(res => {
          if (this.isStatus) {
            this.data = res.data.items.filter(item => item.point !== -1)
            this.calStatus = res.data.calStatus
            this.status = res.data.status
          } else {
            this.data = res.data.reviews
            this.calStatus = res.data.addStatus
          }
          this.loading = false
        })
        .catch(err => console.error(err))
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}
