






































import { Component, Vue, Model, Prop, Emit, Watch } from 'vue-property-decorator'
import { AcademicLevelController } from '@/services/request.service'
import moment from 'moment'

@Component
export default class EditRecord extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly record!: any

  private data: Array<any> = []
  private moment = moment
  private allTagList: any = [
    {
      key: 'standing',
      value: '良好',
      enValue: 'Good Standing',
      borderColor: 'rgba(82, 197, 26, 1)',
      color: 'rgba(82, 197, 26, 0.25)',
    },
    {
      key: 'warning',
      value: '学术警告',
      enValue: 'Academic Warning',
      borderColor: 'rgba(249, 219, 19, 1)',
      color: 'rgba(249, 219, 19, 0.25)',
    },
    {
      key: 'probation',
      value: '学术察看',
      enValue: 'Academic Probation',
      borderColor: 'rgba(250, 140, 20, 1)',
      color: 'rgba(250, 140, 20, 0.25)',
    },
    {
      key: 'noLabel',
      value: '无状态',
      enValue: 'No Status',
      borderColor: 'white',
      color: 'white',
    },
    {
      key: 'review',
      value: '学术审查',
      enValue: 'Academic Review',
      borderColor: 'rgba(245, 32, 45, 1)',
      color: 'rgba(245, 32, 45, 0.25)',
    },
  ]

  @Watch('visible')
  private onVisibleChange(newVal): void {
    if (newVal) {
      AcademicLevelController.getEditLogs(this.record.semesterId, this.record.studentId)
        .then(res => {
          this.data = res.data.sort((a, b) => b.modifyTime - a.modifyTime)
        })
        .catch(err => console.error(err))
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}
