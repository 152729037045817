












































import { Component, Vue, Model, Prop, Emit, Watch } from 'vue-property-decorator'
import { AcademicLevelController } from '@/services/request.service'
// import moment from 'moment'

@Component
export default class ChangeLabel extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly record!: any
  @Prop() private readonly editType!: string
  @Prop() private readonly options!: Array<any>

  private form: any = {
    label: undefined,
  }
  private confirmLoading: boolean = false

  private get calcBg(): any {
    return this.editType === 'status'
      ? this.findLabel(this.record.statusCal)
      : this.findLabel(this.record.addStatusCal)
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private findLabel(key): any {
    return this.options.find(item => item.key === key)
  }

  // private data: Array<any> = []
  // private loading: boolean = false
  // private moment = moment

  @Watch('visible')
  private onVisibleChange(newVal): void {
    if (newVal) {
      this.form.label = this.editType === 'status' ? this.record.status : this.record.addStatus
    }
  }

  // private getCourseList(): void {
  //   this.data = []
  //   this.loading = true
  //   CurriculumController.getRearrangeById(this.modificationId)
  //     .then(res => {
  //       this.data = res.data.rearrangeCourses
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  //     .finally(() => this.loading = false)
  // }
  private changeLabel(label): any {
    const form = this.$refs['form'] as any
    form.validate(valid => {
      if (valid) {
        this.confirmLoading = true
        AcademicLevelController.updateAcademicLevel(
          this.editType,
          this.record.semesterId,
          this.record.studentId,
          this.form.label
        )
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
          })
          .catch(err => console.error(err))
          .finally(() => {
            this.confirmLoading = false
            this.$emit('confirm')
            this.closeModal()
          })
      }
    })

    // return this.form.label
  }

  @Emit('change')
  private closeModal(): boolean {
    const form = this.$refs['form'] as any
    form.resetFields()
    return false
  }
}
